import React from "react"
import { Link } from "react-router-dom"

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container">
      <div className="card d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 my-lg-5 py-5 px-3 px-lg-5 custom-rounded">
        <div className="row">
          <div className="col-12">
            <h2 className="title title dark-color font-33 font-bolder">
              Informativa Privacy web
            </h2>
          </div>
          <div className="col-12 dark-color font-16">
            <p>&nbsp;</p>
            <p>
              <strong>
                <br />
                CHI SIAMO?
              </strong>
            </p>
            <p>
              World Advisor S.r.l. con sede legale in Selargius, Via Peretti n.
              2/, 09047 - CA, C.F. 13826511001 e n. iscrizione&nbsp;REA
              CA-301042.
            </p>
            <p>
              La societ&agrave; gestisce, attraverso la assistenza di tecnici
              specializzati nominati responsabili del trattamento, i siti{" "}
              <Link to="/">https://www.eshoppingadvisor.com/it</Link>,{" "}
              <Link to="https://business.eshoppingadvisor.com/">
                https://business.eshoppingadvisor.com
              </Link>
              ,{" "}
              <Link to="https://eshoppingadvisor.com/blog">
                https://eshoppingadvisor.com/blog
              </Link>
              ,{" "}
              <Link to="https://www.ecommercesicuro.com/">
                https://www.ecommercesicuro.com
              </Link>
              .
            </p>
            <p>
              Ai fini del regolamento europeo sulla protezione dei dati, in
              particolar modo del Regolamento Generale sulla Protezione dei Dati
              (Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio
              del 27 aprile 2016 relativo alla protezione delle persone fisiche
              con riguardo al trattamento dei dati personali, di seguito
              &ldquo;GDPR&rdquo;), World Advisor S.r.l. &egrave; titolare del
              trattamento dei suoi dati personali (di seguito
              &ldquo;Titolare&rdquo;).
            </p>
            <p>
              Le decisioni strategiche riguardanti le finalit&agrave; e i mezzi
              di trattamento dei dati personali degli utenti e dei visitatori
              dei due portali vengono sempre prese dal Titolare.
            </p>
            <p>
              Se Lei &egrave; titolare di un e-commerce o un professionista che
              utilizza la piattaforma informatica messa a disposizione dal
              Titolare nonch&eacute; se Lei usa i servizi di caricamento dati a
              titolo professionale in forza del contratto con World Advisor, i
              suoi dati sono gestiti dalla World Advisor S.r.l..
            </p>
            <p>
              E-Dare di Carlo Pala &egrave; nominato responsabile del
              trattamento dei dati insieme ai dipendenti e collaboratori del
              Titolare.
            </p>
            <p>
              Ciascun utente professionale e non professionale pu&ograve;
              contattare il nostro responsabile della protezione dei dati
              inviando un'email a{" "}
              <Link to="mailto:info@eshoppingadvisor.com">
                info@eshoppingadvisor.com
              </Link>{" "}
              o via posta, all&rsquo;indirizzo del Titolare.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Sez. I &ndash; TRATTAMENTO IN GENERALE</strong>
            </p>
            <p>
              <strong>
                Che tipo di dati personali raccogliamo e utilizziamo, per quali
                finalit&agrave; e su quali basi giuridiche
              </strong>
            </p>
            <p>
              La natura dei dati personali che raccogliamo e utilizziamo,
              cos&igrave; come le finalit&agrave; del trattamento e le relative
              basi giuridiche sulle quali il trattamento si fonda, dipendono
              dalla tipologia di utente e dal diverso utilizzo del Sito, della
              App (ove installata) o dei servizi.
            </p>
            <p>In particolare:</p>
            <ol>
              <li>
                <strong>se sei un utente consumer o un visitatore</strong> o un
                fruitore della nostra App si applicano le previsioni di cui alla
                Sezione II della presente Informativa;
              </li>
              <li>
                <strong>
                  se sei un professionista titolare di un esercizio commerciale
                  su internet che utilizza a titolo professionale il portale
                </strong>
                , si applicano le previsioni di cui alla Sezione III della
                presente Informativa.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              Qualora il Titolare tratti i tuoi dati personali basandosi sul
              proprio interesse legittimo ai sensi dell&rsquo;art. 6.1(f) del
              GDPR, il Titolare avr&agrave; realizzato un test sul bilanciamento
              dei propri interessi con i tuoi diritti fondamentali, in modo tale
              da assicurarsi che questi ultimi non vengano lesi o messi a
              rischio dall&rsquo;esercizio del nostro legittimi interesse.
              Ricorda che puoi sempre contattarci per esprimere la tua
              opposizione al trattamento da noi effettuato.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Condivisione dei tuoi dati con soggetti terzi</strong>
            </p>
            <p>
              Possiamo divulgare i tuoi dati personali ad altre societ&agrave;
              esclusivamente allo scopo di fornirti i servizi richiesti e
              consentirti la migliore fruizione del portale.
            </p>
            <p>
              Potremmo inoltre condividere i tuoi dati personali con fornitori
              esterni, ancora una volta al solo scopo di fornirti i servizi
              richiesti. A meno che non ti venga comunicato diversamente, e ti
              venga richiesto il relativo consenso per il trasferimento dei dati
              ad altro titolare del trattamento, ciascuna di queste terze parti
              agisce come responsabile del trattamento dei dati su nostra
              istruzione, e ha sottoscritto un contratto di nomina del
              responsabile del trattamento con noi ai sensi dell&rsquo;art. 28
              GDPR. Tra essi, vi sono:
            </p>
            <ul>
              <li>
                provider di servizi di cloud hosting e di manutenzione di
                server,
              </li>
              <li>
                fornitori di strumenti di comunicazione telefonica o digitale,
              </li>
              <li>fornitori di strumenti di assistenza clienti,</li>
              <li>consulenti esterni,</li>
              <li>
                fornitori di servizi di pagamento, banche, agenzie di
                riferimento creditizie e di prevenzione delle frodi, e compagnie
                di assicurazione,
              </li>
              <li>
                societ&agrave; IT che ci forniscono software o servizi analoghi,
                e/o servizi di sicurezza;
              </li>
              <li>
                societ&agrave; che hanno stipulato con noi un contratto e sono
                nominate responsabili per il trattamento.
              </li>
            </ul>
            <p>
              Possiamo divulgare i tuoi dati per ottemperare ad eventuali
              requisiti o dettami di legge o regolamentari, per imporre il
              rispetto delle nostre politiche o condizioni d&rsquo;uso o del
              contratto di prestazione servizi, per contattare autorit&agrave;
              giudiziarie o altre autorit&agrave; pubbliche o per ottemperare
              alle loro legittime richieste, o per proteggere i nostri diritti.
            </p>
            <p>
              Eccetto nei casi sopra menzionati, non trasferiremo le tue
              informazioni personali a terzi senza il tuo consenso.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                Quali sono i tuoi diritti riguardo al trattamento dei dati
                personali
              </strong>
            </p>
            <p>
              Ai sensi del GDPR, e indipendentemente dal tipo di utilizzo del
              sito o della piattaforma e dei servizi da te effettuato, hai i
              seguenti diritti:
            </p>
            <ul>
              <li>
                hai il diritto di essere informato sul trattamento dei tuoi dati
                personali (ossia per quali finalit&agrave;, che tipo di dati
                personali, a quali destinatari vengono comunicati, periodi di
                conservazione, qualsiasi fonte di terze parti da cui sono stati
                ottenuti, verifica di decisioni automatizzate, inclusa la
                profilazione, la logica utilizzata, nonch&eacute; l'importanza e
                le conseguenze previste). La presente Informativa, cos&igrave;
                come (ove applicabile) le previsioni delle condizioni generali
                pubblicate nel sito internet o eventuali altre informative o
                notifiche relative al trattamento dei dati personali messe a
                disposizione dal Titolare, assolvono al tuo diritto di essere
                informato;
              </li>
            </ul>
            <p>hai il diritto di:</p>
            <ul>
              <li>
                richiederci una copia dei dati personali che trattiamo; o di
                cancellare (se ritieni che non abbiamo il diritto di
                conservarli) o di rettificare (se ritieni che siano inesatti) i
                tuoi dati personali;
              </li>
              <li>
                opporti a, e limitare quindi il, trattamento dei tuoi dati da
                parte nostra (nel qual caso, avrai accesso soltanto a quelle
                funzionalit&agrave; dei Servizi che non necessitano del tipo di
                trattamento a cui ti sei opposto);
              </li>
              <li>
                revocare ogni eventuale consenso al trattamento dati da te
                previamente dato (la revoca del consenso non fa venir meno la
                legittimit&agrave; dei trattamenti posti in essere prima della
                revoca stessa, o sulla base di presupposti legali diversi dal
                consenso);
              </li>
              <li>
                richiedere la &ldquo;portabilit&agrave;&rdquo; dei tuoi dati da
                noi conservati in formato digitale (ovverosia, di ricevere in un
                formato strutturato, di uso comune e leggibile da dispositivo
                automatico, i dati personali che ti riguardano per trasferirli
                ad altro titolare del trattamento, o di ottenere tale
                trasferimento direttamente, qualora ci&ograve; sia tecnicamente
                fattibile);
              </li>
              <li>presentare reclamo;</li>
            </ul>
            <p>
              Tutto ci&ograve; potrai farlo anche contattandoci via e-mail
              all&rsquo;indirizzo sopra indicato.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Decisioni automatizzate e profilazione</strong>
            </p>
            <p>
              Utilizziamo alcuni servizi di terzi che prevedono la gestione
              automatizzata di alcuni dati quali l&rsquo;indirizzo IP e
              l&rsquo;indirizzo email per funzionalit&agrave; di tipo tecnico.
            </p>
            <p>
              In genere, non ricorriamo a trattamenti di dati personali che
              risultano in decisioni basate interamente ed unicamente sul
              trattamento automatizzato dei tuoi dati personali ad eccezione dei
              dati raccolti attraverso cookies tecnici. Non utilizziamo alcun
              sistema o strumento di profilazione per trattare i tuoi dati,
              n&eacute; processi decisionali interamente automatizzati che
              abbiano come base i tuoi dati personali.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Collegamenti ad altri siti web</strong>
            </p>
            <p>
              Il nostro Sito e la nostra App possono contenere collegamenti ad
              altri siti, app o piattaforme, anche tramite pulsanti
              &ldquo;social&rdquo;. Facciamo quanto possibile per assicurarci
              che tali link siano sempre diretti a siti, app o piattaforme che
              condividono i nostri standard elevati per il rispetto della
              privacy ma, non siamo in ogni caso responsabili del contenuto,
              della sicurezza o delle privacy policy di altri siti web, e un
              collegamento sul nostro sito non costituisce un'approvazione al
              sito in questione. Una volta trasferito a un altro sito, app o
              piattaforma, l'utente &egrave; soggetto ai termini e alle
              condizioni di questi ultimi (ivi incluse la relativa informativa
              sulla privacy e le pratiche sottostanti). Ti invitiamo a
              consultare le condizioni di servizio e le relative informative o
              politiche sulla privacy applicabili a questi siti, app, o
              piattaforme, prima di condividere con essi i tuoi dati personali.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Come proteggiamo i tuoi dati</strong>
            </p>
            <p>
              Il Titolare adotta misure di sicurezza di natura tecnica, fisica,
              elettronica, operativa e amministrativa, adeguate a proteggere i
              tuoi dati personali da accessi non autorizzati. Seguiamo gli
              standard accettati dal settore per proteggere le informazioni
              personali che ci vengono fornite, sia durante la trasmissione che
              dopo la ricezione.
            </p>
            <p>
              Purtroppo, la trasmissione di informazioni tramite internet (ivi
              inclusa la posta elettronica) non &egrave; sempre completamente
              sicura. Nonostante il nostro costante impegno al fine di
              assicurare la massima protezione dei tuoi dati personali, non
              possiamo garantire la sicurezza dei tuoi dati nell&rsquo;atto
              della trasmissione di questi ultimi al nostro sito, App o
              Piattaforma, specialmente qualora la trasmissione avvenga con
              modalit&agrave; non sicure. L&rsquo;utente si assume quindi il
              rischio derivante da detta trasmissione. Una volta ricevute le tue
              informazioni, utilizzeremo procedure rigorose e funzioni di
              sicurezza adeguate per impedire ogni accesso o condivisione non
              autorizzati.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Contattaci</strong>
            </p>
            <p>
              Per qualsiasi domanda relativa ai tuoi dati personali, puoi
              contattarci via email all&rsquo;indirizzo{" "}
              <Link to="mailto:info@eshoppingadvisor.com">
                info@eshoppingadvisor.com
              </Link>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>II. TRATTAMENTO DATI UTENTI (CONSUMATORI)</strong>
            </p>
            <p>
              Se sei un utente/visitatore del nostro sito o App, questa sezione
              si applica a te.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                A) Che dati personali trattiamo; come li raccogliamo; per quali
                finalit&agrave;, e su quale base giuridica, li trattiamo
              </strong>
            </p>
            <p>
              <strong>
                <em>
                  1. Creazione dell&rsquo;Account e registrazione per
                  l&rsquo;utilizzo dei nostri servizi
                </em>
              </strong>
            </p>
            <p>
              Raccogliamo i tuoi dati personali direttamente al momento della
              registrazione da parte tua nel nostro sito o app, per
              l&rsquo;utilizzo dei servizi quali la pubblicazione della
              recensione e la condivisione di una tua esperienza di acquisto. Al
              momento dell&rsquo;iscrizione ti chiediamo infatti di fornire i
              dati di base, compreso il tuo indirizzo e-mail e, se lo vuoi, il
              tuo numero di telefono. Potresti registrarti, quando attivato,
              anche utilizzando piattaforme social come Facebook o Google. In
              questo caso ti verr&agrave; chiesto di consentire a tali
              societ&agrave; di condividere alcune delle tue informazioni
              personali con noi (come indicato nella pagina di registrazione).
            </p>
            <p>
              Devi anche aggiungere ulteriori informazioni come il tuo nome,
              cognome, sesso se richiesto. Salviamo queste informazioni nei
              nostri sistemi per consentirti di utilizzare i nostri servizi e
              per garantire che tu recensisca effettive esperienze di acquisto.
              Il nostro servizio ti consente, tra le altre cose, di: ricevere
              offerte da parte di negozi on line, rispondere a recensioni,
              inviare messaggi e/o comunicare con e-shop, salvare le tue
              informazioni personali nel tuo Account. Puoi anche controllare la
              cronologia delle tue visite, e gestire il tuo Account.
            </p>
            <p>
              Dal tuo Account utente potrai gestire le notifiche automatiche e
              di sistema (ad esempio, le finestre pop-up).
            </p>
            <p>
              Quando ti registri per utilizzare i nostri servizi accetti quindi
              le condizioni di contratto e stipuli quindi un contratto
              giuridicamente vincolante ed accetti di rispettare la policy del
              Titolare. La necessit&agrave;, da parte nostra, di dare esecuzione
              agli obblighi di tale contratto e di consentirti di accedere ai
              servizi, costituisce una valida base giuridica per il trattamento
              dei tuoi dati personali, come specificato all'articolo 6.1(b) del
              GDPR.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <em>2. Utilizzo dei servizi</em>
              </strong>
            </p>
            <p>
              Caricamento recensioni: quando vuoi caricare una recensione dovrai
              fornirci:
            </p>
            <ul>
              <li>nome e cognome,</li>
              <li>numero di telefono,</li>
              <li>indirizzo email,</li>
              <li>data dell&rsquo;acquisto;</li>
              <li>bene/servizio acquistato;</li>
              <li>prova dell&rsquo;acquisto</li>
              <li>
                qualsiasi informazione aggiuntiva che tu decida di condividere.
              </li>
            </ul>
            <p>
              Conserveremo questi dati all&rsquo;interno della piattaforma e li
              trasmetteremo ai nostri dipendenti e collaboratori nonch&eacute;
              saranno accessibili a terzi fornitori di servizi informatici e
              web.
            </p>
            <p>
              Dopo aver trasmesso i tuoi dati personali diverranno disponibili
              al pubblico di internet esclusivamente il tuo nome e nick name ed
              il testo della tua recensione. Tale trattamento sar&agrave;
              soggetto alla politica di trattamento e di conservazione dei dati
              personali del Titolare.
            </p>
            <p>
              Potremo inviarti (via messaggio telefonico o e-mail) una
              comunicazione di conferma della pubblicazione, e/o un promemoria
              per partecipare a eventuali promozioni e operazioni commerciali,
              potremo comunicare al solo negozio presso cui hai acquistato la
              prova del Tuo acquisto.
            </p>
            <p>
              Ti avviseremo in caso di cancellazione della Tua recensione che
              potr&agrave; avvenire in caso di mancanza della prova di acquisto
              o di falsit&agrave; della prova di acquisto.
            </p>
            <p>
              Acconsentire al trattamento di dati per operazioni commerciali e
              di marketing &egrave; facoltativo ma non potrai partecipare a
              promozioni di eventuali e-commerce nostri clienti.
            </p>
            <p>&nbsp;</p>
            <p>
              Storia nell&rsquo;Account: uno dei servizi che offriamo agli
              utenti &egrave; di salvare i propri dati all&rsquo;interno del tuo
              Account. Ci&ograve; ti consente, tra l&rsquo;altro, di:
            </p>
            <ul>
              <li>
                conservare i tuoi dati sugli acquisti in un unico
                &ldquo;luogo&rdquo;, e
              </li>
              <li>avere sempre a disposizione i dati relativi.</li>
            </ul>
            <p>
              La necessit&agrave;, da parte nostra, di dare esecuzione alle
              condizioni di servizio e di consentirti di accedere ai servizi,
              costituisce una valida base giuridica per il trattamento dei tuoi
              dati personali, come specificato all'articolo 6.1(b) del GDPR.
            </p>
            <p>
              Non tratteremo dati personali sulla salute, orientamento politico
              o sesso, razza, religione cos&igrave; come neppure dati giudiziari
              salvo tue violazioni a norme di legge o alla policy del Titolare.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <em>3. Comunicazioni</em>
              </strong>
            </p>
            <p>
              In quanto utente dei servizi con un tuo proprio Account, se
              acconsenti, riceverai da parte nostra comunicazioni relative a
              temi che potrebbero essere di tuo interesse (ovverosia,
              strettamente connessi ai servizi da te utilizzati): ad esempio,
              comunicazioni relative a nuove funzionalit&agrave;, nuovi
              prodotti, servizi aggiuntivi o ancillari, promozioni, notizie ed
              altri argomenti attinenti ai servizi o novit&agrave; riguardanti
              le iniziative del Titolare che potrebbero essere di tuo interesse.
            </p>
            <p>
              La base giuridica per l&rsquo;invio di tali comunicazioni (via
              messaggio telefonico o e-mail) &egrave; l&rsquo;interesse
              legittimo ai sensi dell&rsquo;art 6.1(f) del GDPR. Ciononostante,
              trattandosi di comunicazioni di natura potenzialmente commerciale,
              avrai sempre il diritto di opporti alla ricezione, nel qual caso
              smetteremo di contattarti, fatta eccezione per (a) le
              comunicazioni di servizio e/o richieste da parte del negozio on
              line che hai recensito o presso il quale hai acquistato il
              prodotto anche tramiti invito alla recensione poich&eacute; questi
              messaggi provengono dal negozio online attraverso la nostra
              piattaforma (il nostro cliente/negozio on line utilizza i nostri
              Servizi) ma non sono dipendenti direttamente da noi e/o (b) altre
              comunicazioni di servizio, di natura non-commerciale relative al
              tuo Account o ai Servizi (ad esempio: modifiche alle condizioni
              contrattuali, notifiche di malfunzionamento, messaggi di natura
              legale o regolamentare).
            </p>
            <p>Altri dati e finalit&agrave; del trattamento</p>
            <p>
              Nell&rsquo;ambito dell&rsquo;utilizzo del sito, dell&rsquo;App o
              della piattaforma, potremmo ottenere altre tipologie di dati, tra
              cui, ad esempio: informazioni relative tuo dispositivo (computer o
              telefono cellulare), indirizzo IP, fuso orario e lingua, o il
              browser che utilizzi. Raccoglieremo anche informazioni relative
              alle tempistiche, modalit&agrave; e durata di utilizzo dei Servizi
              da parte tua (primo e ultimo utilizzo, durata della sessione
              nell&rsquo;Account). Se utilizzi la App, potremmo anche ottenere i
              dati sulla tua posizione tramite GPS (avrai sempre la
              possibilit&agrave; di disattivare questa funzionalit&agrave;
              direttamente sul tuo dispositivo mobile).
            </p>
            <p>
              Tratteremo questi insiemi di dati di natura tecnica (i
              &ldquo;Metadati&rdquo;) per:
            </p>
            <ul>
              <li>
                rispondere a, e difenderci da, eventuali reclami (tuoi o di
                terzi) relativi ai Servizi e al tuo utilizzo degli stessi, e/o
              </li>
              <li>
                gestire e pianificare le nostre attivit&agrave; anche di natura
                commerciale (ad esempio, per come i nostri utenti useranno i
                nostri servizi in futuro e stimare le tendenze sulle loro
                esigenze e preferenze). In tal caso, nella maggior parte dei
                casi i dati utilizzati verranno anonimizzati (ad esempio, il
                modo in cui navighi sul nostro sito web), ma in alcuni casi i
                dati, se letti insieme ad altri dati, potrebbero rivelare la tua
                identit&agrave;.
              </li>
            </ul>
            <p>
              Trattiamo queste informazioni sulla base dei nostri interessi
              legittimi, che costituiscono una base giuridica per il trattamento
              dei dati personali ai sensi dell'articolo 6.1(f) del GDPR. Ricorda
              che avrai in ogni momento il diritto di opporti al trattamento di
              questi dati.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                B) &Egrave; possibile fornire dati personali di altre persone?
              </strong>
            </p>
            <p>NO.</p>
            <p>&nbsp;</p>
            <p>
              <strong>
                C) Agiamo anche in qualit&agrave; di &ldquo;responsabili del
                trattamento&rdquo; dei dati per conto di e-commerce
              </strong>
            </p>
            <p>
              Anche i negozi on line sono fruitori dei nostri servizi. I nostri
              servizi consentono ai negozi on line di caricare e salvare i dati
              personali dei propri acquirenti, le informazioni sulle visite ai
              loro siti internet e le informazioni riguardanti gli acquisti.
              Consentono loro, inoltre, di inviare comunicazioni e messaggi di
              testo o e-mail ai loro clienti tramite la nostra Piattaforma.
            </p>
            <p>
              Quando trattiamo i tuoi dati su istruzione dell&rsquo;e-commerce
              presso cui hai acquistato agiamo anche in qualit&agrave; di
              responsabili del trattamento dei dati (ai sensi dell'articolo 28
              del GDPR).
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>D) Conservazione e cancellazione dei tuoi dati</strong>
            </p>
            <p>
              Per quanto riguarda i dati personali da te forniti, li
              conserveremo nei nostri sistemi solo per il tempo necessario per
              gli scopi sopra indicati o per quanto necessario per rispettare
              gli obblighi legali a cui siamo soggetti.
            </p>
            <p>
              Il periodo in cui conserviamo i tuoi dati varier&agrave; a seconda
              del tipo di informazioni e delle finalit&agrave; per le quali li
              utilizziamo. In generale, conserveremo i nostri registri per un
              massimo di 10 anni dopo la fine del tuo rapporto con noi, per
              ottemperare ai nostri obblighi legali.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>E) Condivisione dei tuoi dati con soggetti terzi</strong>
            </p>
            <p>
              Quando pubblichi una recensione trasmetteremo al negozio recensito
              i tuoi dati ed il contenuto della recensione. Il tuo consenso
              &egrave;, in questo caso, necessario per poter portare a termini i
              Servizi.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>III. SEZIONE DEDICATA AI PROFESSIONISTI </strong>
            </p>
            <p>
              <strong>
                A) Che dati personali trattiamo; come li raccogliamo; per quali
                finalit&agrave;, e su quale base giuridica, li trattiamo
              </strong>
            </p>
            <p>
              Ci fornisci i tuoi dati quando ti registri sulla piattaforma
              (creando il tuo Account) e cominci a pubblicizzare il tuo
              esercizio commerciale on line.
            </p>
            <p>
              Al momento della registrazione o della stipula del Contratto da
              parte tua raccoglieremo i dati relativi alla tua attivit&agrave;
              professionali e altre informazioni utili per la creazione del tuo
              Account e del tuo profilo che sar&agrave; visibile agli utenti. Le
              informazioni fornite possono includere o riguardare, tra
              l&rsquo;altro:
            </p>
            <ul>
              <li>
                il tuo indirizzo email e/o il tuo numero di telefono utilizzati
                per svolgere la tua attivit&agrave;;
              </li>
              <li>la tua immagine,</li>
              <li>
                qualsiasi altra informazione che ci fornisci durante il processo
                di registrazione nella piattaforma.
              </li>
            </ul>
            <p>
              Nell&rsquo;ambito dell&rsquo;utilizzo dei servizi da parte tua,
              potremmo ottenere altre tipologie di dati, tra cui, ad esempio:
              informazioni relative tuo dispositivo (computer o telefono
              cellulare), indirizzo IP, fuso orario e lingua, o il browser che
              utilizzi. Raccoglieremo anche informazioni relative alle
              tempistiche, modalit&agrave; e durata di utilizzo dei Servizi da
              parte tua (primo e ultimo utilizzo, durata della sessione
              nell&rsquo;Account).
            </p>
            <p>
              Tratteremo questi insiemi di dati di natura tecnica (i
              &ldquo;Metadati&rdquo;) per:
            </p>
            <ul>
              <li>
                rispondere a, e difenderci da, eventuali reclami (tuoi o di
                terzi) relativi ai Servizi e al tuo utilizzo degli stessi, e/o
              </li>
              <li>
                gestire e pianificare la nostra attivit&agrave; in
                collaborazione con Te.
              </li>
            </ul>
            <p>
              Trattiamo queste informazioni sulla base del contratto e dei
              nostri interessi legittimi, che costituiscono una base giuridica
              per il trattamento dei dati personali ai sensi dell'articolo
              6.1(f) del RGPD. Ricorda che avrai in ogni momento il diritto di
              opporti al trattamento di questi dati.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>B) Conservazione e cancellazione dei tuoi dati</strong>
            </p>
            <p>
              Per quanto riguarda i dati personali da te forniti all&rsquo;atto
              della registrazione sulla Piattaforma o nell&rsquo;ambito della
              fornitura dei Servizi, che trattiamo in qualit&agrave; di
              &ldquo;titolari del trattamento&rdquo; (ai sensi del GDPR) sulla
              base della nostra relazione con te, li conserveremo nei nostri
              sistemi solo per il tempo necessario per gli scopi sopra indicati
              o per quanto necessario per rispettare gli obblighi legali a cui
              siamo soggetti.
            </p>
            <p>
              Il periodo in cui conserviamo i tuoi dati sar&agrave; per un
              massimo di 10 anni dopo la fine del tuo rapporto con noi, per
              ottemperare ai nostri obblighi legali.
            </p>
            <p>
              <strong>POLITICA DEI COOKIE</strong>
            </p>
            <p>
              Questa pagina descrive quali informazioni vengono da noi raccolte
              attraverso i cookie, come li usiamo e perch&eacute; a volte
              abbiamo bisogno di memorizzare e conservare questi cookie.
              Spieghiamo inoltre in questa pagina come impedire la
              memorizzazione di questi cookie, anche se ci&ograve; potrebbe
              ridurre o "interrompere" alcuni elementi e funzionalit&agrave; del
              sito web.
            </p>
            <p>
              <strong>Cosa sono i cookie?</strong>
            </p>
            <p>
              I cookie sono piccoli file che vengono scaricati sul tuo computer
              o su qualsiasi altro dispositivo utilizzato per navigare nel
              nostro sito web. Quasi tutti i siti web professionali utilizzano i
              cookie. Di solito, un cookie include le seguenti informazioni: il
              nome del sito web da cui si proviene; per quanto tempo il cookie
              rimarr&agrave; sul tuo computer o dispositivo; e un valore (di
              solito un numero univoco generato casualmente). Alcuni cookie
              possono includere dati aggiuntivi, in particolare relativi al fuso
              orario o alla lingua utilizzata per la navigazione nei siti web.
            </p>
            <p>
              <strong>Che tipo di cookie utilizziamo?</strong>
            </p>
            <p>
              Alcuni cookie sono sempre attivi quando ci visiti e non puoi
              disattivarli a meno che non modifichi le impostazioni del browser.
              Chiamiamo questi cookie necessari. Li utilizziamo per assicurarci
              che i nostri servizi digitali funzionino correttamente e
              consentano le funzionalit&agrave; principali dei siti web, come
              l'accesso degli utenti e la gestione degli account. Sono utili
              anche per analizzare come la nostra piattaforma viene utilizzata
              dagli utenti.
            </p>
            <p>
              Utilizziamo anche cookie funzionali per rendere la tua esperienza
              pi&ugrave; <em>user-friendly</em>, in particolare per memorizzare
              le tue informazioni sui nostri siti web e per personalizzare il
              contenuto dei nostri servizi.
            </p>
            <p>
              Utilizziamo anche i cookie sulle prestazioni per osservare come
              vengono utilizzati i nostri servizi e per ottenere statistiche
              sull'utilizzo.
            </p>
            <p>
              Utilizziamo anche cookie di terze parti. Questi cookie potrebbero
              tracciare il modo in cui utilizzi diversi siti Web, incluso il
              nostro. Ad esempio, potresti ricevere i cookie di un'azienda di
              social media quando accedi al nostro sito Web utilizzando un
              plug-in di social media. Puoi disattivare questi cookie.
            </p>
            <p>
              Cookie di Marketing utilizzati per la pubblicit&agrave; mirata:
              puoi disattivare questi cookie.
            </p>
            <p>
              <strong>Come utilizziamo i cookie?</strong>
            </p>
            <p>
              Utilizziamo i cookie per una serie di motivi descritti di seguito.
              Per utilizzare al meglio il nostro sito Web, ti consigliamo di
              impostare il tuo dispositivo in modo che accetti tutti i cookie.
              Tuttavia, utilizzando le impostazioni del tuo dispositivo,
              potresti disabilitare o limitare alcuni tipi di cookie.
            </p>
            <p>
              Fornirti una migliore esperienza come utente: I cookie vengono
              utilizzati per una serie di cose che aiutano a migliorare la tua
              esperienza online. Possono aiutare in diversi modi, tra cui:
              garantire un caricamento pi&ugrave; veloce dei nostri siti web,
              mantenerti connesso anche su dispositivi diversi, rendere gli
              accessi pi&ugrave; veloci e pi&ugrave; accessibili ricordando i
              dettagli del tuo cliente, ricordando il modo in cui hai utilizzato
              il nostro servizio in precedenza, permettendoti di avere maggiore
              facilit&agrave; e pi&ugrave; accessibilit&agrave; al sito o
              assicurando che le nostre pagine siano ottimizzate per il tuo
              browser o dispositivo.
            </p>
            <p>
              Adattare i nostri servizi alle tue preferenze: I cookie possono
              aiutarci a presentarti contenuti che soddisfano i tuoi interessi e
              le tue preferenze. I cookie ci aiutano anche a personalizzare il
              nostro sito web per te.
            </p>
            <p>
              Migliorare la sicurezza: Utilizziamo alcuni cookie per garantire
              la sicurezza dei nostri siti web.
            </p>
            <p>
              Miglioramento dei nostri siti Web: Utilizziamo i cookie per
              migliorare i nostri servizi, prevedere il modo in cui gli utenti
              utilizzano i nostri siti Web e per rendere i siti Web migliori per
              i nostri utenti.
            </p>
            <p>
              Marketing: Utilizziamo alcuni cookie per fornirti annunci sui
              nostri servizi. Utilizziamo questi cookie in collaborazione con i
              nostri partner di marketing (Google e Facebook).
            </p>
            <p>
              <strong>Cookie di terze parti</strong>
            </p>
            <p>
              Utilizziamo cookie di terze parti per perseguire i nostri
              interessi legittimi e per migliorare i nostri siti Web per i
              nostri utenti.
            </p>
            <p>
              <strong>Per quanto tempo conserviamo i cookie?</strong>
            </p>
            <p>
              Cookie di sessione: questi cookie durano solo per una data
              sessione che hai con noi e vengono automaticamente cancellati
              quando chiudi il browser.
            </p>
            <p>
              Cookie persistenti: questi cookie durano anche se il browser
              &egrave; stato chiuso o il dispositivo &egrave; stato spento e
              sono attivi per un periodo di tempo definito nel cookie.
              Utilizziamo cookie persistenti quando abbiamo bisogno di sapere
              chi sei per un tempo maggiore di quello necessario per una sola
              sessione di navigazione. Ad esempio, li usiamo per ricordare le
              tue preferenze per la prossima volta che visiti il nostro sito.
            </p>
            <p>
              <strong>Disabilitazione dei cookie</strong>
            </p>
            <p>
              Puoi impedire l'impostazione dei cookie modificando le
              impostazioni del tuo browser: puoi trovare le istruzioni su come
              farlo nella tabella sottostante. Tieni presente che la
              disabilitazione dei cookie influir&agrave; sulla
              funzionalit&agrave; di questo e di molti altri siti web visitati.
              La disabilitazione dei cookie di solito comporta anche la
              disabilitazione di alcune funzionalit&agrave; e caratteristiche
              dei nostri servizi. Pertanto si consiglia di non disabilitare i
              cookie.
            </p>
            <p>
              <strong>Cookie Policy completa e gestione dei cookie</strong>
            </p>
            <p>
              Per approfondire e conoscere quali cookie sono utilizzati sui
              nostri siti, e modificare le impostazioni dei cookie, consulta la{" "}
              <Link to="/it/informativa-cookies">
                informativa sull'utilizzo dei cookie
              </Link>
              . Puoi anche controllare&nbsp;e modificare le impostazioni dei
              cookie cliccando sul relativo link che trovi in fondo alle pagine
              del sito.
            </p>
            <p>
              Per conoscere quali dati vengano raccolti si prega di far
              riferimento alle informative privacy di ciascun servizio.
            </p>
            <p>&nbsp;</p>
            <p>
              <Link
                className="iubenda-black no-brand iubenda-noiframe iubenda-embed iub-legal-only iub-no-markup iubenda-noiframe iub-body-embed"
                title="Privacy Policy"
                to="https://www.iubenda.com/privacy-policy/63549753/section/data-processing-detailed-info"
              >
                Privacy Policy
              </Link>
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
